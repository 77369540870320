import { graphql, navigate } from 'gatsby';

import DynamicZone from '../../../components/dynamic-zone/dynamic-zone';
import FloatingButton from '../../../components/floating-button/floating-button';
import Layout from '../../../components/layout';
import React from 'react';

const AssetAndDataTokenizationPage = (props) => {
    const components = props.data.cms.assetAndDataTokenizationPageTemplate?.components || null;

    if (!components) {
        if (typeof window !== `undefined`) {
            navigate(`${props.pageContext.locale === `pl` ? `/pl` : ``}/404`);
        }
        return null;
    }

    return (
        <Layout location={props.location} locale={props.pageContext.locale}>
            <FloatingButton />
            <DynamicZone components={components} />
        </Layout>
    );
};
export const query = graphql`
query ($locale: String!){
  cms {
    assetAndDataTokenizationPageTemplate(locale: $locale) {
      components {
        ... on STRAPI_ComponentComponentsHeader {
          __typename
          title
          textBoxCustomClasses
          headerSubtitle: subtitle
          primaryImage {
            url
          }
          mobileContentOffset
          contentOffset
          backgroundImageHeight
          backgroundImage {
            url
          }
          ImagesWithScroll {
            Title
            logos {
              url
            }
          }
        }
        ... on STRAPI_ComponentComponentsBenefits {
          __typename
          topPusherHeightMobile
          imagesTailwindClasses
          mobileItemsToLeft
          topPusherHeight
          sectionTitle {
            tailwindClasses
            ytLink
            title
            style
            description
          }
          benefitsScrollColor
          itemsToLeft
          Benefit {
            title
            subtitle
            image {
              url
            }
          }
        }
        ... on STRAPI_ComponentHorizontalTabsHorizontalTabs {
          __typename
          topPusherHeightMobile
          topPusherHeight
          tabsDarkBackground
          tabHeight
          tabs {
            imagesTailwindClasses
            type
            title
            subtitle
            scrollColor
            link
            labelColour
            labelImage {
              url
            }
            label
            isLabelImageVisibleOnDesktop
            imagesTitles
            imagesMetadata
            images {
              url
            }
          }
          sectionTitle {
            tailwindClasses
            ytLink
            title
            style
            description
          }
        }
        ... on STRAPI_ComponentSeoSeo {
          __typename
          seoDescription
          seoLink
          seoTitle
          seoImages {
            url
          }
        }
      }
    }
  }
}

`;

export default AssetAndDataTokenizationPage;
